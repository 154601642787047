import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useWindowSize from '../../utils/windowSize'

// Components
import Toolbar from '@mui/material/Toolbar'

// Styles
import './ordersCover.scss'

// Images
import backgroundImg from '../../assets/images/orders-cover/header-image-orders-page.png'
import TrustedBy from '../TrustedBy'

const ordersCover = () => {
  const responsive = useWindowSize().responsive
  var bgImg = responsive ? { background: '' } : { background: `url('${backgroundImg}') no-repeat right` }
  const { t } = useTranslation()
  return (
    <div className='ordersCover mx-auto'>
      <Toolbar />
      <div
        style={bgImg}
        className={'ordersCoverBgImg d-flex flex-row flex-wrap justify-content-center justify-content-lg-between'}
      >
        <div className='d-flex flex-column mt-s-80 ordersCoverLeft'>
          <b className='sub-headline'>ORDER SMARTER, PRODUCE BETTER</b>
          <h1 className='heading-1 text-grey-10'>{t('Your all-in-one solution for orders and production.')}</h1>
          <span className='text-grey-10 font-size-18 ln-22 pt-s-16'>
            {t('Embrace streamlined processes, efficiency, transparency, and collaboration. Navigate from discovering sellers to placing orders and secure payments. Say goodbye to sourcing complexity - welcome  speed, success and a sustainable future')}
            {t('.')}
          </span>
         <div className='order-cover-button'>
          <a
              href={`${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`}
              className='btn btn-sm btn-primary mt-s-48 mb-md-s-68'
            >
              {t('Start project')}
          </a>
          <a
              href="https://calendly.com/yarabanyatta/30min"
              className='btn btn-sm btn-outline mt-s-48 mb-md-s-68 btn-book-demo'
            >
              {t('Book a demo')}
          </a>
         </div>
        </div>
        {/* <div className='d-flex flex-column mt-auto overflowHidden'>
          <TrustedBy mode={'dark'} disposition={'column'} show={true} />
        </div> */}
      </div>
    </div>
  )
}

export default ordersCover